import { lazy } from 'react';
import AuthenticatedComponent from '../components/IsAuthenticated';
import ErrorTestPage from '../pages/Management/ErrorTestPage';
import InvalidDomain from '../pages/Management/InvalidDomain';
import S3Explorer from '../pages/Apps/S3Explorer';
import CancellationRequestAdmin from '../pages/Management/CancellationRequestAdmin';
// import Pickup from '../pages/ManageShipments/Pickup';

const Index = lazy(() => import('../pages/Index'));
const LoginCoverNumber = lazy(() => import('../pages/Authentication/LoginCoverNumber'));
const Purchase = lazy(() => import('../pages/ManageShipments/Purchase'));

const Calendar = lazy(() => import('../pages/Apps/Calendar'));
const Users = lazy(() => import('../pages/Admin/Users'));
const Roles = lazy(() => import('../pages/Admin/Roles'));
const EditRole = lazy(() => import('../pages/Admin/EditRole'));
const Resources = lazy(() => import('../pages/Admin/Resources'));
const EditAccess = lazy(() => import('../pages/Admin/EditAccess'));

const Bookings = lazy(() => import('../pages/Booking/Bookings2'));
const AllShipments = lazy(() => import('../pages/ManageShipments/AllShipments'));
const Schedule = lazy(() => import('../pages/ManageShipments/Schedule'));
const ChangeShipmentNumber = lazy(() => import('../pages/ManageShipments/ChangeShipmentNumber'));
const PickupType = lazy(() => import('../pages/ManageShipments/PickupType'));
const OrderDetails = lazy(() => import('../pages/ManageShipments/OrderDetails'));
const Warehouse = lazy(() => import('../pages/ManageShipments/Warehouse'));
const Vendor = lazy(() => import('../pages/ManageShipments/Vendor'));
const AddAddress = lazy(() => import('../pages/ManageShipments/AddAddress'));

const MissedCalls = lazy(() => import('../pages/Management/MissedCalls'));
const CancellationRequest = lazy(() => import('../pages/Management/CancellationRequest'));
const Defaulters = lazy(() => import('../pages/Management/Defaulters'));
const SeoContents = lazy(() => import('../pages/Management/SeoContents'));
// const Profile = lazy(() => import('../pages/Users/Profile'));
// const AccountSetting = lazy(() => import('../pages/Users/AccountSetting'));
// const KnowledgeBase = lazy(() => import('../pages/Pages/KnowledgeBase'));
const ERROR404 = lazy(() => import('../pages/Pages/Error404'));
const ERROR500 = lazy(() => import('../pages/Pages/Error500'));
const ERROR503 = lazy(() => import('../pages/Pages/Error503'));
const Maintenence = lazy(() => import('../pages/Pages/Maintenence'));

const Error = lazy(() => import('../components/Error'));

const routes = [
    // dashboard
    {
        path: '/',
        element: (
            <AuthenticatedComponent allowedRoles={['admin', 'user']}>
                <Index />
            </AuthenticatedComponent>
        ),
    },

    {
        path: '/s3explorer',
        element: <S3Explorer />,
    },
    // {
    //     path: '/apps/s3explorer/:prefix*',
    //     element: <S3Explorer />,
    // },

    {
        path: '/shipment-booking',
        element: <Bookings />,
    },
    {
        path: '/reverse-shipments',
        element: <AllShipments />,
    },
    {
        path: '/forward-shipments',
        element: <AllShipments />,
    },
    {
        path: '/purchase-shipments',
        element: <AllShipments />,
    },
    {
        path: '/all-shipments',
        element: <AllShipments />,
    },
    {
        path: '/missed-calls',
        element: <MissedCalls />,
    },
    {
        path: '/invalid-domains',
        element: <InvalidDomain />,
    },
    {
        path: '/change-shipment-number',
        element: <ChangeShipmentNumber />,
    },
    {
        path: '/error',
        element: <ErrorTestPage />,
    },
    {
        path: '/seo-contents',
        element: <SeoContents />,
    },

    {
        path: '/warehouse',
        element: <Warehouse />,
    },
    {
        path: '/vendor',
        element: <Vendor />,
    },
    {
        path: '/schedule-shipment',
        element: <Schedule />,
    },
    {
        path: '/add-address',
        element: <AddAddress />,
    },
    {
        path: '/defaulters',
        element: <Defaulters />,
    },
    {
        path: '/pickup/:type',
        element: <PickupType />,
    },
    {
        path: '/pickup/:type/:order_id',
        element: <OrderDetails />,
    },
    {
        path: '/purchase',
        element: <Purchase />,
    },
    {
        path: '/apps/calendar',
        element: <Calendar />,
    },
    {
        path: '/error',
        element: <ERROR404 />,
        layout: 'blank',
    },
    {
        path: '/pages/error404',
        element: <ERROR404 />,
        layout: 'blank',
    },
    {
        path: '/pages/error500',
        element: <ERROR500 />,
        layout: 'blank',
    },
    {
        path: '/pages/error503',
        element: <ERROR503 />,
        layout: 'blank',
    },
    {
        path: '/pages/maintenence',
        element: <Maintenence />,
        layout: 'blank',
    },
    //Authentication
    {
        path: '/auth/login',
        element: <LoginCoverNumber />,
        layout: 'blank',
    },
    {
        path: '/users',
        element: <Users />,
    },
    {
        path: '/edit-access/:user_id',
        element: <EditAccess />,
    },
    {
        path: '/roles',
        element: <Roles />,
    },
    {
        path: '/roles/edit/:role_name',
        element: <EditRole />,
    },
    {
        path: '/resources',
        element: <Resources />,
    },

    {
        path: '*',
        element: <Error />,
        layout: 'blank',
    },
];

export { routes };
