import { PropsWithChildren, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './store';
import { toggleRTL, toggleTheme, toggleLocale, toggleMenu, toggleLayout, toggleAnimation, toggleNavbar, toggleSemidark } from './store/themeConfigSlice';
import store from './store';
import { getUser } from './lib/busniessFunctions';
import { useNavigate } from 'react-router-dom';
import Loader from './components/Loader';
import LoginCoverNumber from './pages/Authentication/LoginCoverNumber';
// import { use } from 'i18next';

function App({ children }: PropsWithChildren) {
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleTheme(localStorage.getItem('theme') || themeConfig.theme));
        dispatch(toggleMenu(localStorage.getItem('menu') || themeConfig.menu));
        dispatch(toggleLayout(localStorage.getItem('layout') || themeConfig.layout));
        dispatch(toggleRTL(localStorage.getItem('rtlClass') || themeConfig.rtlClass));
        dispatch(toggleAnimation(localStorage.getItem('animation') || themeConfig.animation));
        dispatch(toggleNavbar(localStorage.getItem('navbar') || themeConfig.navbar));
        dispatch(toggleLocale(localStorage.getItem('i18nextLng') || themeConfig.locale));
        dispatch(toggleSemidark(localStorage.getItem('semidark') || themeConfig.semidark));
        // toast.success('hi ')
    }, [dispatch, themeConfig.theme, themeConfig.menu, themeConfig.layout, themeConfig.rtlClass, themeConfig.animation, themeConfig.navbar, themeConfig.locale, themeConfig.semidark]);

    const { user } = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        // console.log('app loading');
        const checkLoginStatus = async () => {
            try {
                const user = await getUser(dispatch);
                // console.log('user', user);


                if (user) {
                    if (user.user_uid) localStorage.setItem('uid', user.user_uid);
                    // toast.success(`Welcome ${user.first_name} sir!`);
                    // navigate('/');
                } else {
                    navigate('/auth/login');
                }
            } catch (error) {
                // console.log(error);
                navigate('/auth/login');
                toast.error('Something went wrong!');
            } finally {
                setShowLoader(false);
            }
        };

        checkLoginStatus();
    }, []);

    if (showLoader) {
        return <Loader />;
    }
    if (!user && !showLoader) {
        <LoginCoverNumber />;
    }

    return (
        <div
            className={`${(store.getState().themeConfig.sidebar && 'toggle-sidebar') || ''} ${themeConfig.menu} ${themeConfig.layout} ${themeConfig.rtlClass
                } main-section antialiased relative font-nunito text-sm font-normal`}
        >
            <Toaster position="top-center" reverseOrder={false} />

            {children}
        </div>
    );
}

export default App;
