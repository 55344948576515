import axios from 'axios';

const API_URL = import.meta.env.VITE_LAMBDA_API_URL as string;

export const getPresignedUrl = async (fileName: string, fileType: string, action: 'upload' | 'download') => {
    const { data } = await axios.post(`${API_URL}/s3/presigned-url`, { fileName, fileType, action });
    return data.url;
};

export const listObjectsAndFolders = async (prefix: string = '', continuationToken: string | null = null) => {
    const { data } = await axios.get(`${API_URL}/s3/list`, { params: { prefix, continuationToken } });
    return data;
};

export const deleteFile = async (fileName: string) => {
    const { data } = await axios.delete(`${API_URL}/s3/objects/${encodeURIComponent(fileName)}`);
    return data;
};

export const deleteMultipleFiles = async (fileKeys: string[]) => {
    const { data } = await axios.post(`${API_URL}/s3/delete-multiple`, { fileKeys });
    return data;
};

export const createFolder = async (folderName: string) => {
    const { data } = await axios.post(`${API_URL}/s3/folders`, { folderName });
    return data;
};

export const deleteFolder = async (folderName: string) => {
    const { data } = await axios.delete(`${API_URL}/s3/folders/${encodeURIComponent(folderName)}`);
    return data;
};

export const multipartUploadToS3 = async (file: File, prefix: string = '') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('prefix', prefix);
    const { data } = await axios.post(`${API_URL}/s3/multipart-upload`, formData);
    return data;
};

export const multipleMultipartUploadToS3 = async (files: File[], prefix: string = '') => {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file)); // Use the same field name as in the backend

    formData.append('prefix', prefix); // Add any additional parameters like the prefix if needed

    const { data } = await axios.post(`${API_URL}/s3/multipart-upload-multiple`, formData);
    return data;
};

export const renameObjectInS3 = async (oldKey: string, newKey: string) => {
    const { data } = await axios.post(`${API_URL}/s3/rename-object`, { oldKey, newKey });
    return data;
};

export const compressImage = async (fileKeys: string[]) => {
    const { data } = await axios.post(`${API_URL}/compression/compress-multiple`, { fileKeys });
    return data;
};
