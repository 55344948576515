import React from 'react';
import { S3Object } from '../../types/s3';

// const CLOUDFRONT_URL = 'https://images.sharepal.in';
const CLOUDFRONT_URL = import.meta.env.VITE_CLOUDFRONT_URL as string;

interface ObjectListProps {
    objects: S3Object[];
    isGridView: boolean;
    selectedItems: string[];
    onSelectItem: (items: string[]) => void;
    onRenameClick: (key: string) => void;
}

const ObjectList: React.FC<ObjectListProps> = ({ objects, isGridView, selectedItems, onSelectItem, onRenameClick }) => {
    const handleSelectItem = (key: string) => {
        onSelectItem(selectedItems.includes(key) ? selectedItems.filter((item) => item !== key) : [...selectedItems, key]);
    };

    return (
        <ul className={`${isGridView ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4' : 'space-y-2'}`}>
            {objects?.map((object) => (
                <li
                    key={object.Key}
                    className={`group bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-3 overflow-hidden transition duration-300 ease-in-out ${
                        isGridView ? 'flex flex-col' : 'flex items-center'
                    } ${selectedItems.includes(object.Key) ? 'ring-2 ring-blue-500' : 'hover:shadow-md'}`}
                >
                    <div className={`flex items-center   ${isGridView ? 'w-full mb-3'  : 'max-w-max flex-grow '}`}>
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500 transition duration-150 ease-in-out"
                            checked={selectedItems.includes(object.Key)}
                            onChange={() => handleSelectItem(object.Key)}
                            onClick={(e) => e.stopPropagation()}
                        />
                        {isGridView && (
                            <div className="ml-2 text-gray-500 dark:text-gray-400">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>
                    {isGridView && (
                        <div className="w-full aspect-square rounded-lg p-4 bg-gray-100 dark:bg-gray-700">
                            <img src={`${CLOUDFRONT_URL}/${object.Key}`} alt={object.Key} className="w-full h-full rounded-lg aspect-square object-cover" />
                        </div>
                    )}
                    <div className={`flex-grow ${isGridView ? 'p-3' : 'ml-3'}`}>
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate" title={object.Key}>
                            {object.Key}
                        </p>
                        {!isGridView && (
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                {new Date(object.LastModified).toLocaleDateString()} · {(object.Size / 1024).toFixed(2)} KB
                            </p>
                        )}
                    </div>
                    <div className={`flex items-center ${isGridView ? 'p-2 w-full justify-between bg-gray-50 dark:bg-gray-700' : 'pr-3'}`}>
                        <button
                            className="px-3 py-1 bg-blue-100 text-blue-600 text-xs font-medium rounded-full hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                            onClick={(e) => {
                                e.stopPropagation();
                                onRenameClick(object.Key);
                            }}
                        >
                            Rename
                        </button>
                        {isGridView && (
                            <a
                                href={`${CLOUDFRONT_URL}/${object.Key}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200"
                            >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg>
                            </a>
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default ObjectList;
